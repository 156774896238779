<template>
    <client-page>
        <sub-visual
        sh="회사소개"
        sc="에프원시큐리티가 여러분의 정보를 함께 보호하겠습니다."
        tabActive="연혁"
        icon="/images/sub/visual/sv1-icon.svg"
        bg="/images/sub/visual/sv1.png">
        </sub-visual>

        <section class="sub-section">
            <v-container>

                <h3 class="sub-tit--lg mb-28 mb-lg-54" data-aos="fade-up">
                    <span class="color-point">F1 Security</span> 는 <br class="d-none d-md-block">
                    언제나 고객과 함께 나아갑니다
                </h3>

                <div class="w-100 pa-30 pa-sm-40 px-lg-0 py-lg-80 radius-md his-bg">
                    <v-row no-gutters align="end" class="color-white">
                        <v-col cols="12" md="5" class="d-flex justify-center mb-12 mb-md-0">
                            <h4 class="sub-tit--md">
                                에프원 시큐리티의 <br class="d-none d-md-block">
                                발자취 입니다.
                            </h4>
                        </v-col>
                        <v-col cols="12" md="7">
                            <p class="page-text">
                                2012년 10월 정보보안 컨설팅으로 출발하여 웹보안 관련 정보보안 솔루션 개발 및 <br class="d-none d-lg-block">
                                서비스를 제공하는 과학기술정보통신부 지정 정보보호 전문서비스 기업 입니다.
                            </p>
                        </v-col>
                    </v-row>
                </div>

                <div class="tab--wrap mt-60 mt-lg-100">
                    <v-tabs v-model="tab" class="mb-40 mb-lg-60">
                        <v-tab>2022-2020</v-tab>
                        <v-tab>2019-2017</v-tab>
                        <v-tab>2016-2014</v-tab>
                        <v-tab>2013-2011</v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <!-- S::2022-2020 -->
                        <v-tab-item>
                            <v-row>
                                <v-col cols="7" sm="5" md="4" lg="5">
                                    <div class="pr-md-20">
                                        <v-img class="d-block" max-width="398" src="/images/sub/about/history/his-tit1.svg"></v-img>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="8" lg="7">
                                    <div class="mt-18 mt-lg-70">
                                        <div class="mb-24 mb-lg-74">
                                            <div class="his-year">
                                                <h4 class="sub-tit--md pb-10 pb-lg-24 border-bottom font-weigth-bold line-height-1 mb-16 mb-lg-32">
                                                    2022
                                                </h4>
                                            </div>
                                            <div class="page-text">
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">01</p>
                                                    <p class="color-gray">
                                                        2022년 웹취약점 점검 및 웹보안도구 보급 용역 사업 수주(한국인터넷진흥원)
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">01</p>
                                                    <p class="color-gray">
                                                        2022년 침해사고 탐지정보 분석·대응 지원 용역 사업 수주(한국인터넷진흥원)
                                                    </p>
                                                </div>
                                                <div class="d-flex">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">01</p>
                                                    <p class="color-gray">
                                                        2022년 내PC 돌보미 서비스 운영 사업 수주(한국인터넷진흥원)
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-24 mb-lg-74">
                                            <div class="his-year">
                                                <h4 class="sub-tit--md pb-10 pb-lg-24 border-bottom font-weigth-bold line-height-1 mb-16 mb-lg-32">
                                                    2021
                                                </h4>
                                            </div>
                                            <div class="page-text">
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">01</p>
                                                    <p class="color-gray">
                                                        방산보안연구소 MOU 체결
                                                    </p>
                                                </div>
                                                <div class="d-flex">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">02</p>
                                                    <p class="color-gray">
                                                        한국정보기술전략혁신학회(KIITI) 미래융합기술연구소 MOU 체결
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="his-year">
                                                <h4 class="sub-tit--md pb-10 pb-lg-24 border-bottom font-weigth-bold line-height-1 mb-16 mb-lg-32">
                                                    2020
                                                </h4>
                                            </div>
                                            <div class="page-text">
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">01</p>
                                                    <p class="color-gray">
                                                        특허등록(블록체인을 이용한 공동주택 관리 시스템 및 방법)
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">02</p>
                                                    <p class="color-gray">
                                                        상표등록(특허청 Secure DNS), 상표 등록(특허청 WSFinder 상표 등록)
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">07</p>
                                                    <p class="color-gray">
                                                        한국인터넷진흥원 “2020년 클라우드 보안서비스 고도화 지원사업” 사업자 선정
                                                    </p>
                                                </div>
                                                <div class="d-flex">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">09</p>
                                                    <p class="color-gray">
                                                        UWSS(통합웹보안서비스) 출시
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <!-- E::2022-2020 -->

                        <!-- S::2019-2017 -->
                        <v-tab-item>
                            <v-row>
                                <v-col cols="7" sm="5" md="4" lg="5">
                                    <div class="pr-md-20">
                                        <v-img class="d-block" max-width="365" src="/images/sub/about/history/his-tit2.svg"></v-img>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="8" lg="7">
                                    <div class="mt-18 mt-lg-70">
                                        <div class="mb-24 mb-lg-74">
                                            <div class="his-year">
                                                <h4 class="sub-tit--md pb-10 pb-lg-24 border-bottom font-weigth-bold line-height-1 mb-16 mb-lg-32">
                                                    2019
                                                </h4>
                                            </div>
                                            <div class="page-text">
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">02</p>
                                                    <p class="color-gray">
                                                        상표 등록(특허청 F1SECURITY 상표 등록)
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">03</p>
                                                    <p class="color-gray">
                                                        특허등록(DNS 서버에 기반한 감염 시스템의 원점 추적 방지 및 시스템 웹공격 탐지 <br class="d-none d-xl-block">
                                                        및 차단 시스템 및 그방법)
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">03</p>
                                                    <p class="color-gray">
                                                        상표 출원(특허청 WSFinder 상표 출원)
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">04</p>
                                                    <p class="color-gray">
                                                        특허등록(악성코드 탐지 장치 및 방법), 상표출원(특허청 webcastle 상표출원)
                                                    </p>
                                                </div>
                                                <div class="d-flex">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">05</p>
                                                    <p class="color-gray">
                                                        과학기술정보통신부 "정보보호 전문서비스 기업 지정"
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-24 mb-lg-74">
                                            <div class="his-year">
                                                <h4 class="sub-tit--md pb-10 pb-lg-24 border-bottom font-weigth-bold line-height-1 mb-16 mb-lg-32">
                                                    2018
                                                </h4>
                                            </div>
                                            <div class="page-text">
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">01</p>
                                                    <p class="color-gray">
                                                        특허출원(웹 쉘 탐지 시스템), 해커프리 웹콘 웹방화벽 인수
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">04</p>
                                                    <p class="color-gray">
                                                        한국인터넷진흥원 ‘중소기업 정보보호 컨설팅 사업자 선정
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">05</p>
                                                    <p class="color-gray">
                                                        서울시 창업성장센터(월곡) 본사이전
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">06</p>
                                                    <p class="color-gray">
                                                        ISO27001 인증 획득
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">06</p>
                                                    <p class="color-gray">
                                                        특허등록(웹 쉘 탐지 시스템), 특허출원(웹 공격 탐지 및 차단 시스템 및 그 방법)
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">06</p>
                                                    <p class="color-gray">
                                                        가산 STVX로 기술연구소 확장이전
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">08</p>
                                                    <p class="color-gray">
                                                        특허등록(웹사이트 위변조 탐지 방법 및 시스템)
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">09</p>
                                                    <p class="color-gray">
                                                        한국해킹보안협회 기업부문 수상
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">10</p>
                                                    <p class="color-gray">
                                                        해외PCT출원(웹 공격 탐지 및 차단 시스템 및 그 방법, 악성코드 탐지 장치 및 방법)
                                                    </p>
                                                </div>
                                                <div class="d-flex">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">10</p>
                                                    <p class="color-gray" style="letter-spacing: -0.02em !important;">
                                                        특허출원(DNS 서버에 기반한 감염 시스템의 원점 추적 방법 및 시스템), 코리아리더대상 <br class="d-none d-xl-block">
                                                        정보보안산업 부문 대상
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="his-year">
                                                <h4 class="sub-tit--md pb-10 pb-lg-24 border-bottom font-weigth-bold line-height-1 mb-16 mb-lg-32">
                                                    2017
                                                </h4>
                                            </div>
                                            <div class="page-text">
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">02</p>
                                                    <p class="color-gray">
                                                        상표등록(특허청 WMDS 상표 등록)
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">05</p>
                                                    <p class="color-gray">
                                                        정보통신산업진흥원(NIPA) 2017년 GSIP 아이디어형 SaaS 개발 지원과제 선정
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">06</p>
                                                    <p class="color-gray">
                                                        서울형 강소기업 선정 (서울특별시 협약)
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">07</p>
                                                    <p class="color-gray">
                                                        서울시 Hi Seoul 브랜드 인증 사용권 기업 선정
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">08</p>
                                                    <p class="color-gray">
                                                        국보연 특허 기술이전(프로세스 행위 예측 기법을 이용한 비정형 악성코드 탐지방법 및 <br class="d-none d-xl-block">
                                                        그 시스템)
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">08</p>
                                                    <p class="color-gray">
                                                        특허출원 (머신러닝을 이용한 악성코드 유포지 위험도 분석시스템)
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">08</p>
                                                    <p class="color-gray">
                                                        서울시 ‘퍼지해시를 이용한 웹 셸 유사도 탐지 시스템 개발’ 사업자 선정
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">10</p>
                                                    <p class="color-gray">
                                                        상표 출원(특허청 F1SECURITY 상표 출원)
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">12</p>
                                                    <p class="color-gray">
                                                        기술혁신형 중소기업(Inno-Biz) 인증
                                                    </p>
                                                </div>
                                                <div class="d-flex">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">12</p>
                                                    <p class="color-gray">
                                                        특허취득(머신러닝을 이용한 악성코드 유포지 위험도 분석 시스템)
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <!-- E::2019-2017 -->

                        <!-- S::2016-2014 -->
                        <v-tab-item>
                            <v-row>
                                <v-col cols="7" sm="5" md="4" lg="5">
                                    <div class="pr-md-20">
                                        <v-img class="d-block" max-width="371" src="/images/sub/about/history/his-tit3.svg"></v-img>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="8" lg="7">
                                    <div class="mt-18 mt-lg-70">
                                        <div class="mb-24 mb-lg-74">
                                            <div class="his-year">
                                                <h4 class="sub-tit--md pb-10 pb-lg-24 border-bottom font-weigth-bold line-height-1 mb-16 mb-lg-32">
                                                    2016
                                                </h4>
                                            </div>
                                            <div class="page-text">
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">02</p>
                                                    <p class="color-gray">
                                                        웹사이트 악성코드 유포탐지 솔루션(F1-WMDS V2.0) 출시
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">04</p>
                                                    <p class="color-gray">
                                                        GS인증 1등급(소프트웨어품질인증서, TTA) 획득
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">05</p>
                                                    <p class="color-gray">
                                                        웹사이트 악성코드 유포탐지 시스템 프로그램 등록 (한국저작권위원회)
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">06</p>
                                                    <p class="color-gray">
                                                        서울시 일자리창출 지원사업 대상 선정
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">09</p>
                                                    <p class="color-gray">
                                                        정보통신산업진흥원(NIPA) 2016년 GSIP 정보생활형 활용 SaaS 개발 지원대상 선정
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">09</p>
                                                    <p class="color-gray">
                                                        조달청 구매물품등록 (웹사이트 악성코드 유포탐지 솔루션 F1-WMDS V2.0)
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">11</p>
                                                    <p class="color-gray">
                                                        클라우드서비스 확인서 취득(한국클라우드산업협회)
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">11</p>
                                                    <p class="color-gray">
                                                        특허취득(웹 사이트의 악성코드 탐지 및 차단시스템)
                                                    </p>
                                                </div>
                                                <div class="d-flex">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">12</p>
                                                    <p class="color-gray">
                                                        클라우드스토어 씨앗 WMDS CLOUD 서비스 등록
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-24 mb-lg-74">
                                            <div class="his-year">
                                                <h4 class="sub-tit--md pb-10 pb-lg-24 border-bottom font-weigth-bold line-height-1 mb-16 mb-lg-32">
                                                    2015
                                                </h4>
                                            </div>
                                            <div class="page-text">
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">05</p>
                                                    <p class="color-gray">
                                                        특허취득(웹사이트 악성코드유포 탐지 및 차단 시스템)
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">06</p>
                                                    <p class="color-gray">
                                                        중기청 기술혁신과제 지원대상 선정
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">06</p>
                                                    <p class="color-gray">
                                                        IBK기업은행 투자기업 인증 취득
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">08</p>
                                                    <p class="color-gray">
                                                        익스플로잇 코드를 탐지하는 네트워크 보안시스템 및 방법 기술이전 (한국전자통신연구원)
                                                    </p>
                                                </div>
                                                <div class="d-flex">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">06</p>
                                                    <p class="color-gray">
                                                        특허취득(악성프로그램 탐지장치 및 그 방법)
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="his-year">
                                                <h4 class="sub-tit--md pb-10 pb-lg-24 border-bottom font-weigth-bold line-height-1 mb-16 mb-lg-32">
                                                    2014
                                                </h4>
                                            </div>
                                            <div class="page-text">
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">03</p>
                                                    <p class="color-gray">
                                                        한국인터넷진흥원 웹사이트 악성코드탐지 기술이전
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">10</p>
                                                    <p class="color-gray">
                                                        홈페이지 악성코드 유포탐지 솔루션(F1-WMDS) 출시
                                                    </p>
                                                </div>
                                                <div class="d-flex">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">12</p>
                                                    <p class="color-gray">
                                                        서울 Rising Star 기업 선정
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <!-- E::2016-2014 -->

                        <!-- S::2013-2011 -->
                        <v-tab-item>
                            <v-row>
                                <v-col cols="7" sm="5" md="4" lg="5">
                                    <div class="pr-md-20">
                                        <v-img class="d-block" max-width="340" src="/images/sub/about/history/his-tit4.svg"></v-img>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="8" lg="7">
                                    <div class="mt-18 mt-lg-70">
                                        <div class="mb-24 mb-lg-74">
                                            <div class="his-year">
                                                <h4 class="sub-tit--md pb-10 pb-lg-24 border-bottom font-weigth-bold line-height-1 mb-16 mb-lg-32">
                                                    2013
                                                </h4>
                                            </div>
                                            <div class="page-text">
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">04</p>
                                                    <p class="color-gray">
                                                        기업부설연구소 설립
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">05</p>
                                                    <p class="color-gray">
                                                        조달청 조달업체 등록
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">05</p>
                                                    <p class="color-gray">
                                                        소프트웨어 사업자 등록
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">06</p>
                                                    <p class="color-gray">
                                                        벤처기업 인증, 유망창업기업 고용창출지원대상기업 선정
                                                    </p>
                                                </div>
                                                <div class="d-flex">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">07</p>
                                                    <p class="color-gray">
                                                        중소기업 기술보호상담 전문기관 등록
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-24 mb-lg-74">
                                            <div class="his-year">
                                                <h4 class="sub-tit--md pb-10 pb-lg-24 border-bottom font-weigth-bold line-height-1 mb-16 mb-lg-32">
                                                    2012
                                                </h4>
                                            </div>
                                            <div class="page-text">
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">04</p>
                                                    <p class="color-gray">
                                                        호서시니어창업보육센터(서초) 입주
                                                    </p>
                                                </div>
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">08</p>
                                                    <p class="color-gray">
                                                        서울시창업지원센터(구로) 입주 이전
                                                    </p>
                                                </div>
                                                <div class="d-flex">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">10</p>
                                                    <p class="color-gray">
                                                        (주)에프원시큐리티 법인설립
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="his-year">
                                                <h4 class="sub-tit--md pb-10 pb-lg-24 border-bottom font-weigth-bold line-height-1 mb-16 mb-lg-32">
                                                    2011
                                                </h4>
                                            </div>
                                            <div class="page-text">
                                                <div class="d-flex mb-8 mb-lg-20">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">07</p>
                                                    <p class="color-gray">
                                                        설립, 정보보호컨설팅 서비스 전문
                                                    </p>
                                                </div>
                                                <div class="d-flex">
                                                    <p class="min-w-40px min-w-lg-60px font-weight-bold">10</p>
                                                    <p class="color-gray">
                                                        1인창조기업 등록
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <!-- E::2013-2011 -->
                    </v-tabs-items>
                </div>
                
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
    },
    data () {
      return {
        tab: null,
      }
    },
};
</script>

<style lang="scss" scoped>
.his-bg{
    background-image: url(/images/sub/about/history/his-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.his-year{
    position: relative;
}
.his-year::after{
    content: "";
    display: block;
    position: absolute;
    width: 70px;
    height: 2px;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(var(--primary-gra));
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .his-year::after{
        width: 100px;
        height: 3px;
    }
}
@media (min-width:1200px){
}

</style>